/* Hero.css */
.hero {
  position: relative;
  height: 90vh; /* This height will be used to determine the centering vertically */
  width: 100%;
  overflow: hidden;
  display: flex; /* Turn .hero into a flex container */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.filter-form-container-hero{
  padding: 0;
  margin-top: 215px;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  object-fit: cover;
  z-index: -1;
}

.btn1 {
  background-color: #323232;
  color: white; /* Added for better visibility of button text */
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.hero .container {
  padding-top: 15%;
  position: relative;
  z-index: 2;
}

.hero h1 {
  color: #fff;
  font-size: 60px;
}

.hero p {
  color: #fff;
  opacity: 0.8;
}

form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 0 20px;
  z-index: 2;
}

form input, form select {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
  -moz-appearance: none; /* Remove default styling for Firefox */
  -webkit-appearance: none; /* Remove default styling for WebKit browsers */
}

form select {
  background: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat right 10px center; /* Add a custom arrow icon */
  background-color: white; /* Ensure the background is white */
  cursor: pointer; /* Changes the cursor to indicate it's a dropdown */
}

form span {
  font-size: 14px;
  color: grey;
}

input::placeholder, select {
  font-size: 17px;
  color: black;
}

form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

form .box:nth-child(1) {
  border-left: none;
}

@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }

  form {
    display: flex;
    flex-wrap: wrap;
  }

  form .box {
    border-left: none;
    padding: 10px 0; /* Adjusted for mobile responsiveness */
  }

  form input, form select {
    margin-top: 10px; /* Provide spacing between form elements */
    width: calc(50% - 10px); /* Adjust width to fit two elements per row */
  }

  form .btn1 {
    width: 100%; /* Full width button on mobile */
    margin-top: 10px; /* Spacing above the button */
  }
}
