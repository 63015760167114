/* Amenities */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:700|Lato:400');

.unitsAmenities {
  font-family: 'Lato', sans-serif;
  padding: 5px 0;
  text-align: center;
}

.topLine, .bottomLine {
  border: none;
  height: 1px;
  background-color: #232323;
  margin: 0 auto 20px;
  width: 80%; /* Adjust this to match the screenshot */
}

.unitsAmenities h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: #232323;
  margin-bottom: 20px;
}

.amenitiesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.amenityItem {
  display: flex;
  align-items: center;
  margin: 10px; /* Adjust for proper spacing */
}

.amenityLabel {
  margin-left: 8px; /* Space between icon and label */
  font-size: 1rem;
  color: #232323;
}

@media (min-width: 768px) {
  .amenitiesList {
    justify-content: space-around;
  }
}
