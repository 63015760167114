.contact {
  /* Additional styles for the contact section if needed */
}

.contact .contact-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children */
  padding: 30px 0;
}

.contact-info {
  text-align: center; /* Center the text for each contact-item */
  width: 100%; /* Full width */
  margin-bottom: 20px; /* Space between contact info and form */
}

.contact-item {
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center; /* Center the items */
  margin-bottom: 20px;
}

.contact-item i {
  margin-bottom: 10px; /* Space between icon and text */
  font-size: 1.2rem; /* Adjust the size of the icon */
  color: #27ae60; /* Example icon color */
}

.contact form {
  width: 100%; /* Full width for mobile */
  max-width: 500px; /* Maximum width of the form */
  padding: 30px;
}

.contact form div {
  display: flex;
  flex-direction: column; /* Stack the inputs vertically */
  width: 100%;
}

.contact input,
.contact textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px; /* Space between each input/textarea */
}

.contact form button {
  width: 100%;
  border: none;
  background-color: #27ae60;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.social-buttons {
  text-align: center;
  margin-top: 20px;
}

.whatsapp-button {
  display: inline-block;
  background-color: #27ae60;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
}

.social-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: white;
  text-align: center;
  margin: 0 5px;
  font-size: 20px;
  text-decoration: none;
}

.facebook-button {
  background-color: #3b5998;
}

.instagram-button {
  background-color: #E1306C;
}

@media screen and (min-width: 769px) {
  .contact .contact-container {
    flex-direction: column; /* Keep the column direction for desktop */
    align-items: center; /* Center the children */
  }

  .contact form {
    width: 50%; /* Adjust the form width for desktop if needed */
  }

  .contact-info {
    width: 100%; /* Contact info should still span the full width */
    margin-bottom: 0; /* More space between contact info and form */
  }
}

@media screen and (max-width: 768px) {
  .contact h4 {
    margin-bottom: 30px;
  }

  .contact form div {
    flex-direction: column;
  }

  .contact input,
  .contact textarea,
  .contact form button {
    width: 100%; /* Full width for inputs, textarea, and button */
    margin-right: 0; /* Remove margin-right */
  }
}
