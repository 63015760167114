/* projectPage.css */
.project-page {
    width: 100%; /* Ensures that project page takes full width */
    /* Other styles if needed */
}
.project-picture{
    width: 79vw; /* Ensures that the image takes full width */
    height: 325px; /* Fixed height for images */
    object-fit:cover; /* This will cover the area without distorting the aspect ratio */
    object-position: center bottom;
}

.project-picture-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* This centers the image within its parent */

}

.gallery-sliders{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 10px;
    max-width: 80vw;
    max-height: auto;
    margin: 0 auto; /* This centers the .gallery-sliders span within its parent */
}

/* Mobile specific styles - use when less than 480px width */
@media (max-width: 480px) {
    .gallery-sliders {
        flex-direction: column; /* Stacks sliders on top of each other */
        max-width: 100%; /* Utilizes full width */
        gap: 5px; /* Reduces gap for mobile layout */
    }
}
@media (max-width: 480px) {
    .project-page {
      padding: 0 20px; /* Adds padding on the sides */
      box-sizing: border-box; /* Ensures padding is included in width */
    }
  
    .project-picture {
      height: auto; /* Adjusts height to maintain aspect ratio */
    }
  
    .gallery-sliders {
      flex-direction: column; /* Stacks sliders on top of each other */
      max-width: 100%; /* Utilizes full width */
      gap: 5px; /* Reduces gap for mobile layout */
    }
  
    /* Center all the text and content within the sliders and other components */
    .property-title-row, /* Replace with actual class or element selector if different */
    .amenities, /* Replace with actual class or element selector if different */
    .my-slider { /* Replace with actual class or element selector if different */
      text-align: center; /* Centers text */
    }
  
    /* Center images within the sliders */
    .my-slider img { /* Adjust selector based on actual markup and classes */
      display: block; /* Images are block level to allow for margin auto */
      margin-left: auto;
      margin-right: auto;
    }
  }
  