@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Lato", sans-serif;
  background-color: #fff;
}
.container {
  max-width: 80%;
  margin: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}
button {
  border-radius: 5px;
  padding: 17px 30px;
  background: #ffffff;
  border: none;
  color: #000;
  cursor: pointer;
  font-weight: bold;
}
button i {
  margin-right: 5px;
}
.heading {
  text-align: center;
  width: 60%;
  margin: auto;
}
.heading h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 35px;
}
.heading p {
  color: #72809d;
}
textarea,
input {
  border: none;
  outline: none;
  background: none;
}
.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
label {
  color: #2d3954;
  font-size: 14px;
}
.mtop {
  margin-top: 50px;
}
h4 {
  font-weight: 500;
  margin-top: 10px;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}
.padding {
  padding: 80px 0;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.btn2 {
  border-radius: 50px;
  font-size: 20px;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.btn3 {
  background: #ff6922;
  border-radius: 50px;
}
.btn4 {
  background-color: #000;
  color: #fff;
}
.btn5 {
  border-radius: 50px;
  padding: 20px 40px;
  color: #266969;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #27ae601f;
  background: #fff;
}
.back {
  height: 40vh;
  position: relative;
}
.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(17, 40, 72, 0.629);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 120px 0;
}
.back h1 {
  font-size: 40px;
  font-weight: 500;
}
.mb {
  margin-bottom: 80px;
}
@media screen and (max-width: 800px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}
.whatsapp-float {
  position: fixed;   /* Fixed position */
  width: 60px;      /* Width of the button */
  height: 60px;     /* Height of the button */
  bottom: 40px;     /* Distance from the bottom */
  right: 40px;      /* Distance from the right */
  background-color: #25d366; /* WhatsApp color */
  color: #FFF;      /* White color for the icon */
  border-radius: 50px; /* Rounded corners */
  text-align: center;  /* Center the icon */
  font-size: 30px; /* Size of the icon */
  z-index: 100;    /* Make sure it doesn't get hidden behind other elements */
  box-shadow: 2px 2px 3px #999;  /* Slight shadow for 3D effect */
  display: flex;   /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-decoration: none; /* No underline on the text/icon */
}
