/* Header.css */
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #323232;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;

}

.logo-and-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  max-width: 50px; /* Adjust based on your logo size */
  height: auto;
}


.company-name {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 24px; /* Adjust font size as needed */
  margin-left: 15px;
  color: #ffffff; /* Or any color that suits your brand */
}

.nav .nav-list {
  display: flex;
  list-style: none;
  background-color: #323232;
}

.nav .nav-list li a {
  margin-left: 30px;
  transition: 0.5s;
  font-weight: 500;
  color: #ffffff;
}

.nav .nav-list li a:hover {
  color: #27ae60;
}

.toggle {
  display: none;

}

@media screen and (max-width: 768px) {
  .nav .nav-list {
    display: none;
  }

  .nav .small {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #323232;
    color: #ffffff;
    box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
    padding: 20px 0;
  }

  .toggle {
    display: block;
  }
}
