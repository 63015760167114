/* PropertyTitleRow.css */
.property-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 130px;
  min-width: 80%;
}

.title-block {
  text-align: center; /* Align text to the left */
}

.subdivision-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem; /* Adjust to match the design */
  font-weight: bold; /* Adjust if the design requires */
  color: #232323; /* Adjust if the design requires */
  display: inline-block; /* Change to inline-block */
  margin-right: 0.5rem; /* Space between the text and the line */
  text-transform: uppercase;
  position: relative; /* For the pseudo-element positioning */
}

.subdivision-title:after {
  content: "";
  display: inline-block; /* Display inline-block */
  width: 120px; /* Length of the line */
  height: 1px;
  background-color: #9d9d9c; /* Line color */
  position: relative;
  vertical-align: middle; /* Align it with the text */
  margin-left: 0.5rem; /* Space between the text and the line */
}


.title-block h1 {
  font-size: 2.5rem; /* Large font size for main title */
  font-weight: 300; /* Adjust as needed */
  margin: 0; /* Remove default margin */
  color: #232323;
}

.price-block {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align to the right */
}

.price-details {
  text-align: right;
}

.price-title {
  display: block; /* Ensures "Starting Price" appears on its own line */
  font-size: 0.75rem;
  color: #9d9d9c; /* Use the color that suits your design */
  text-transform: uppercase; /* Capitalizes "Starting Price" */
  margin-bottom: 4px; /* Spacing between "Starting Price" and the actual price */
}

.price-container {
  display: flex; /* Aligns the currency and price on the same line */
  align-items: baseline; /* Aligns the text baselines */
  gap: 0.25rem; /* Adjust the space between currency and price if necessary */
}

.currency,
.price {
  font-size: 1.5rem; /* Adjust to match the design */
  line-height: 1.5rem;
  color: #232323; /* Adjust to match the design */
}

.book-button {
  background-color: #0056b3; /* Button color */
  color: white;
  padding: 0.625rem 1.25rem;
  text-decoration: none;
  margin-left: 1.25rem; /* Spacing between price and button */
  border: none; /* Remove borders */
  border-radius: 4px; /* Rounded corners for the button */
  font-size: 1rem;
  cursor: pointer; /* Cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.book-button:hover {
  background-color: #003d82; /* Darker shade on hover for visual feedback */
}

@media only screen and (max-width: 960px) {
  .property-title-row {
    flex-direction: column;
  }
  .title-block,
  .price-block {
    width: 100%;
    text-align: center;
  }
  .book-button {
    margin-top: 10px;
  }
}

/* Additional mobile-specific styles */
@media only screen and (max-width: 480px) {
  .property-title-row {
    margin: 20px 15px; /* Adjusts margin for mobile devices */
    flex-direction: column; /* Stacks elements vertically */
    justify-content: center; /* Centers content vertically */
    align-items: center; /* Centers content horizontally */
  }

  .title-block,
  .price-block {
    width: 100%; /* Full width on mobile */
    text-align: center; /* Center text */
    justify-content: center; /* Center flex items */
  }

  .price-details {
    align-items: center; /* Center the price details */
    flex-direction: column; /* Stack price and currency */
  }

  .price-container {
    justify-content: center; /* Center price and currency */
    flex-direction: column; /* Stack price and currency on mobile */
    gap: 0.25rem; /* Maintain a gap between price and currency */
  }

  .price-block {
    flex-direction: column; /* Ensures elements in price block stack vertically */
    align-items: center; /* Center the items horizontally */
  }

  .book-button {
    margin-top: 10px; /* Adds space above the button */
    width: auto; /* Allows the button to maintain intrinsic width */
  }

  /* Adjust the subdivision title line for mobile */
  .subdivision-title:after {
    width: 80px; /* Shortens the line on mobile */
    margin: 0 auto; /* Center the pseudo-element line */
  }
}