/* Blog.css */

/* .blog-out {
    display: flex;
    align-items: center;
} */

.filter-form-container{
    display: flex;
    justify-content: center;
    margin-top: 0;
}
.container.recent{
    margin-top: 25px;
    margin-bottom: 25px;
}