/* MySlider.css */
#my-slider {
  position: relative;
  width: 100%; /* Full width of the parent element */
  max-width: 80vw; /* Maximum width of the slider */
  height: auto; /* Fixed height for your slider */
  margin: 10px auto; /* Center the slider horizontally */
  overflow: hidden; /* Ensures that nothing spills out of the slider container */
  font-family: 'Lato', sans-serif; /* Your preferred font-family */
  padding: 0;
  margin-bottom: 40px;
}

#my-slider h2 {
  width: 100%; /* Full width to center text properly */
  text-align: center; /* Center the text */
  color: #333; /* Color of the text, adjust as needed */
  font-size: 1.5rem; /* Adjust the size as needed */
  margin-bottom: 20px; /* Space between the heading and the slider */
  font-family: 'Lato', sans-serif; /* Your preferred font-family */
  font-weight: 200; /* Lighter font-weight */
}

#my-slider .slide-container {
  display: flex; /* This enables horizontal layout of the images */
  transition: transform 0.5s ease; /* Slide effect for the image transition */
  /* Remove the width from here */
  height: 300px; /* Fixed height for images */
}

#my-slider .slide-container img {
  flex: 0 0 100%; /* Prevent flex items from growing or shrinking */
  object-fit: cover; /* This will cover the area without distorting the aspect ratio */
  height: 300px; /* This ensures each image has the same height */
  padding: 0;
}

/* Rest of the styles remain the same */


#my-slider img {
  max-width: 100%; /* Maximum width for each image */
  object-fit: cover; /* This will cover the area without distorting the aspect ratio */
  padding: 0;
} 

#left-arrow,
#right-arrow {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  background-color: white; /* Adjust to match your arrow background color */
  border: 1px solid #ddd; /* Adjust to match your arrow border color */
  border-radius: 50%; /* This makes the button round */
  cursor: pointer;
  padding: 10px; /* Adjust padding to scale the size of the arrows */
  z-index: 10;
  width: 30px; /* Adjust width as needed */
  height: 30px; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* This adds a subtle shadow for depth, optional */
}

/* Update the icon sizing and remove the color to let the FontAwesome icon inherit the button text color */
.fa-arrow-left, .fa-arrow-right {
  font-size: 1rem; /* Adjust icon size as needed */
}

/* Adjust the position from the sides if needed */
#left-arrow {
  left: 10px; /* Adjust the position from the left side */
  color: #333; /* Change the icon color on hover */
}

#right-arrow {
  right: 10px; /* Adjust the position from the right side */
  color: #333; /* Change the icon color on hover */
}

/* Add a transition for the hover effect */
#left-arrow:hover,
#right-arrow:hover {
  background-color: #f0f0f0; /* Slightly different background on hover */
  color: #333; /* Change the icon color on hover */
  transition: background-color 0.3s, color 0.3s;
}