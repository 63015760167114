/* Footer.css */
.footerContact {
  background-color: #266969;
  padding: 40px 0;
  color: #fff;
  text-align: center; /* Center align the content */
}

.logo-and-name-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.company-name {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 24px; /* Adjust font size as needed */
  margin-left: 15px;
  color: #ffffff; /* Or any color that suits your brand */
}

.footerContact h1,
.footerContact p {
  margin: 0; /* Removes default margin */
  padding: 5px 0; /* Adds uniform padding for spacing */
  align-items: flex-start;
}
footer {
  background-color: #323232;
  padding: 30px 0;
  color: #fff;
  text-align: center; /* Center align the content */
}

footer .container {
  display: flex; /* Use flex instead of grid */
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: space-between; /* Space out the items */
  align-items: center; /* Align items vertically */
}

footer img {
  width: auto;
  height: auto;
  max-width: 70px; /* Match with the header logo size */
  margin-bottom: 10px; /* Space below the logo */
}

footer h2,
footer h3 {
  font-family: 'Lato', sans-serif;
  font-weight: 700; /* Match with the header font-weight */
  color: #323232; /* Match with the header color */
  margin-bottom: 20px; /* Space below the titles */
}


/* Footer list items */
footer ul {
  display: flex; /* Display list items inline */
  list-style: none; /* Remove list bullets */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  color: #1d2636;
}

footer ul li {
  margin-right: 20px; /* Add space between list items */
}

/* Ensure the last item doesn't have margin on the right */
footer ul li:last-child {
  margin-right: 0;
}

.legal {
  text-align: center;
  padding: 20px;
  background: #1d2636;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%; /* Ensure legal spans the full width */
}


footer input {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  border: none;
  border-radius: 5px;
}

footer .btn5 {
  background-color: #0056b3; /* Button color */
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer; /* Cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.footerContact .btn5:hover,
footer .btn5:hover {
  background-color: #003d82; /* Darker shade on hover for visual feedback */
}

.legal {
  text-align: center;
  padding: 20px;
  background: #323232;
  color: rgba(255, 255, 255, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Additional Media Queries for smaller screens */
@media screen and (max-width: 480px) {
  .footerContact {
    padding: 20px 0; /* Less padding on smaller screens */
  }

  footer {
    padding: 30px 0; /* Less padding on smaller screens */
  }
}
@media screen and (max-width: 800px) {
  footer .container {
    justify-content: center; /* Center items for smaller screens */
  }

  footer ul {
    flex-direction: column; /* Stack list items vertically on smaller screens */
    align-items: center; /* Center align list items */
  }

  footer ul li {
    margin: 10px 0; /* Add space between stacked list items */
  }
}