.flex {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add space between the boxes */
    justify-content: space-between; /* Space out the boxes when they don't wrap */
  }
.flex-filter-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Add space between the boxes */
  justify-content: flex-start; /* Space out the boxes when they don't wrap */
  width: 79vw;
  padding: 0;
  border: #323232;
}
  
  .filter-box {
    /* flex: 1; */
    width: 30%;
    
  }
  .flex-hero-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Space out the boxes when they don't wrap */
    width: 70vw;
    padding: 0;
  }
  .hero-box{
    width:20%;
  }
  /* Use this to make sure the button takes the entire width of a line */
  .btn1 {
    background-color: #323232;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
    flex-basis: 100%; /* Take full width */
  }
  
  /* Style for the filter inputs and selects */
  form input, form select {
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.3);
    margin-top: 5px;
    border-radius: 5px;
    -moz-appearance: none; /* Remove default styling for Firefox */
    -webkit-appearance: none; /* Remove default styling for WebKit browsers */
    background-color: white;
    cursor: pointer;
  }
  
  /* Style for select element arrows */
  form select {
    background: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat right 10px center;
    background-color: white;
  }
  
  /* Additional styles for the form labels */
  form span {
    font-size: 14px;
    color: grey;
  }
  
  /* Placeholder and select font size */
  input::placeholder, select {
    font-size: 17px;
    color: black;
  }
  
  /* Adjustments for responsiveness */
  @media screen and (max-width: 768px) {
    .flex {
      justify-content: center;
    }

    .flex-hero-form{
      padding-left: 7px;
      padding-right: 7px;
    }
  
    .box {
      flex-basis: 100%; /* Make all boxes full width on small screens */
    }
  
    .btn1 {
      width: 100%; /* Full width button on mobile */
    }
  }